.drawer-button {
  width: 100%;
  text-align: left !important;
}

.drawer-button:not(:last-child) {
  margin-bottom: 1rem;
}

.ant-drawer-body {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.ant-collapse-content-box {
    /* padding-top: 0 !important;
    padding-bottom: 0 !important; */
}

.ant-input-affix-wrapper{
    border: 1px transparent !important;
    padding: 4px 13px !important;
    margin-bottom: 1rem;
}

.drawer-search > input {
    padding-left: 10px !important;
}