@import '~antd/dist/antd.css';
/* @import '~prismjs/assets/prism.min.css'; */
@import '~prismjs/themes/prism.min.css';

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 1.6rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --theme__app-panel-background: #ffffff;
  --theme__app-panel-border: #c1c2c4;
  --theme__app-panel-text: #2e2f32;
  --theme__app-panel-scrollbar: #b2b4b6;
  --theme__app-panel-scrollbar--hover: #c1c2c4;
  --theme__primary-btn: #2c9bba;
  --theme__primary-btn--hover: #3eafce;
  --theme__primary-btn-text: #ffffff;
  --theme__secondary-btn: #e6e7e8;
  --theme__secondary-btn--hover: #d9dadb;
  --theme__secondary-btn-text: #2e2f32;
  --theme__canvas-background: #e6e7e8;
  --theme__canvas-healthy-data-link: #8a8d91;
  --theme__canvas-healthy-data-link--hover: #4a4c50;
  --theme__canvas-error-data-link: #da4545;
  --theme__canvas-error-data-link--hover: #bb1212;
  --theme__canvas-filter-link: #ffffff;
  --theme__canvas-filter-link--hover: #c1c2c4;
  --theme__canvas-filter-link--highlight: #3eafce;
  --theme__operator-background: #ffffff;
  --theme__operator-border: #b2b4b6;
  --theme__operator-border--error: #da4545;
  --theme__operator-editor: #f1f1f1;
  --theme__operator-interactive: #2c9bba;
  --theme__operator-interactive--disabled: #9b9ea0;
  --theme__operator-scrollbar: #b2b4b6;
  --theme__operator-scrollbar--hover: #c1c2c4;
  --theme__operator-text: #2e2f32;
  --theme__selection: #3eafce;
  --theme__dataframe-background: #ffffff;
  --theme__dataframe-border: #8a8d91;
  --theme__dataframe-border--hover: #4a4c50;
  --theme__filter-background: #ffffff;
  --theme__filter-border: #8a8d91;
  --theme__filter-border--hover: #4a4c50;
  --theme__filter-foreground: #4a4c50;
  --theme__table-row--header: #ffffff;
  --theme__table-row--odd: #ffffff;
  --theme__table-row--even: #f5f6f7;
  --theme__chart-gridlines: #a2a3a5;
  --theme__chart-text: #393b3f;
}