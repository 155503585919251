.network-request-add-header-flex {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.network-request-header-row-flex {
  /* margin-top: 1rem; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.network-request-header-row-flex > * {
  margin-top: 0.5rem;
}

.network-request-body-flex {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.network-request-response-flex {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.js-code {
  margin-top: 1rem;
  height: 300px;
  overflow: auto;
}

