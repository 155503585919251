

.title {
  font-size: 1.8rem;
}

.cursor-pointer {
  cursor: pointer;
  pointer-events: auto;
}

.top-header {
  position: absolute;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1.0rem;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background: var(--theme__app-panel-background);
  padding: 0 1.3rem;
  height: 4.5rem;
  border-bottom: .1rem solid var(--theme__app-panel-border);
}

.header-left {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: flex-start;
}

.title-container {
  position: relative;
  flex: 1 1 auto;
  justify-content: center;
}

.file-name-view {
  display: flex;
  width: fit-content;
  margin: 0 auto;
  gap: 2.0rem;
}

.file-name-view__name {
  flex: 1 1 auto;
  text-align: center;
  white-space: nowrap;
}
.file-name-view__name, .file-name-view__input {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2.2rem;
}

.file-name-view__name, .file-name-view__input {
  letter-spacing: .1rem;
}

.header-right {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: flex-end;
  gap: 1.5rem;
}

.c-collaborator-list {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: -.6rem;
}

.stream-settings {
  display: flex;
  gap: .3rem;
}

.live-button, .publish-button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  font-weight: 400;
  padding: 0 .8rem;
  height: 2.6rem;
  border-radius: .4rem;
  text-align: center;
  color: var(--theme__app-panel-text);
  background-color: var(--theme__app-panel-background);
  border: .1rem solid var(--theme__app-panel-border);
  transition: border .2s ease-out;
  white-space: nowrap;
}

.share-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  font-weight: 400;
  width: 8.0rem;
  height: 2.6rem;
  border-radius: .4rem;
  text-align: center;
  color: var(--theme__primary-btn-text);
  background-color: var(--theme__primary-btn);
  -webkit-box-shadow: .0rem .4rem 1.6rem rgb(5 0 56 / 3%);
  box-shadow: 0 .4rem 1.6rem #05003806;
}

.stream-settings span, .share-button span {
  font-size: 12px;
  margin-left: 7px;
}

.header-right-small {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: .0rem;
}

.separator {
  position: relative;
  height: 1.6rem;
  margin: 0 .5rem;
  width: .1rem;
  background: #c1c2c4;
}

.user-guide {
  gap: 1.0rem;
  padding: 0 .5rem;
  display: flex;
  height: 2.6rem;
  width: 2.6rem;
  overflow: hidden;
  pointer-events: all;
  font-size: 1.2rem;
  font-weight: 900;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  border-radius: 5.0rem;
  color: #56585c;
}

.user-avatar__photo {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  border-radius: 50%;
  transition: border-radius .15s ease-in -out;
  pointer-events: none;
  background-image: none;
  background-size: 60%;
  background-position: center center;
  background-repeat: no-repeat;
  color: var(--theme__primary-btn-text);
  background-color: var(--theme__primary-btn);
  font-weight: 600;
  font-size: 1.4rem;
  text-transform: uppercase;
  text-align: center;
}

.user-avatar__photo-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.3rem;
  height: 3.3rem;
  border: .3rem solid transparent;
  border-radius: 50%;
  padding: .1rem;
}