.operation_window {
    padding: 1.5rem;
    background-color: var(  --theme__operator-background);
    border-radius: .5rem;
    cursor: move;
    cursor: grab;
}

.operation-title {
    display: flex;
    justify-content: space-between;
    align-content: center;
    margin-bottom: 1rem;
  }
  
  .operation-title-text {
    font-weight: 500;
    font-size: 1.75rem;
    border: 1px solid #fff !important;
    padding: 0 !important;
  }