.canvas {
  position: absolute;
  top: 4.5rem;
  background-color: var(--theme__canvas-background);
  width: 100%;
  max-width: 100%;
  height: calc(100% - 4.5rem);
}

.resizable {
  resize: both;
  overflow: auto;
}

.operation-button {
  margin: 1rem;
}

.drawer-footer {
  position: absolute;
  left: 0px;
  bottom: 24px;
  height: 50px;
  width: 100%;
  /* background: #999; */
  padding: 24px;
  /* height: calc(100vh - 4.5rem - 10rem); */
}
