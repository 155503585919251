.send-to-flex {
  margin-top: 1rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: baseline;
}

.send-to-dropdown {
  flex-grow:1
}
